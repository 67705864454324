<template>
  <div class="app-container">
    <eHeader :query="query" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <!--"localTime":"2020-03-14 14:02:38",
            "code":null,
            "serverTime":0,
            "remark":null,
            "id":10400,
            "login":80028450,
            "type":4,
            "message":"远程主机强迫关闭了一个现有的连接。",
            "dealFlag":1,
            "serverId":204405-->
      <el-table-column prop="id" label="id"/>
      <el-table-column prop="serverId" label="服务器id"/>
      <!-- <el-table-column prop="siteId" label="所属社区"/> -->
      <el-table-column prop="login" label="登陆账号"/>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
            <span>{{getType(scope.row.type)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          <span>{{getStatue(scope.row.dealFlag)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="message" label="消息"/>
      <el-table-column prop="localTime" label="mt4  listener时间"/>
      <el-table-column prop="serverTime" label="mt4服务器时间"/>
      <el-table-column prop="code" label="消息码"/>
      <el-table-column prop="remark" label="备注"/>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/monitor/apiWatch/detail/header'
export default {
  name:'apiLogs',
  components: { eHeader },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'community/crm/queryUserConnectState'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size };
      this.query.serverId && (this.params.serverId = this.query.serverId);
      this.query.login && (this.params.login = this.query.login);
      this.query.siteId && (this.params.siteId = this.query.siteId);
      (this.query.dealFlag !== '') && (this.params.dealFlag = this.query.dealFlag);
      if(this.query.dealFlag === ''){
        delete this.params.dealFlag;
      }
      this.query.startTime && (this.params.startTime = this.parseTime(this.query.startTime));
      this.query.endTime && (this.params.endTime = this.parseTime(this.query.endTime));
      return true
    },
    getType(type){
      let text = '';
      switch (type) {
        case 0:
          text = '启动';
          break;
        case 1:
          text = '停止'
          break;
        case 2:
          text = '连接'
          break;
        case 3:
          text = '断开'
          break;
        case 4:
          text = '重新连接'
          break;
      }
      return text;
    },
    getStatue(statue){
      let text = '';
      switch (statue) {
        case 0:
          text = '成功';
          break;
        case 1:
          text = '待处理'
          break;
        case 2:
          text = '处理中'
          break;
        case 3:
          text = '处理失败'
          break;
      }
      return text;
    }
  }
}
</script>

<style scoped>

</style>
