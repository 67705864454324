<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input
      style="width: 150px;"
      v-model="query.serverId"
      clearable placeholder="服务器id"
      class="filter-item"
      @keyup.enter.native="toQuery" />
    <el-input
      style="width: 150px;"
      v-model="query.login"
      clearable placeholder="login"
      class="filter-item"
      @keyup.enter.native="toQuery" />
    <el-select class="align-top"  v-model="query.dealFlag" filterable placeholder="状态">
      <el-option
        v-for="(item, index) in dealStatusList"
        :key="index"
        :value="item.value"
        :label="item.label"
      >
      </el-option>
    </el-select>
    <el-date-picker
      class="align-top"
      style="width: 150px;"
      v-model="query.startTime"
      type="date"
      placeholder="开始日期">
    </el-date-picker>
    至
    <el-date-picker
      class="align-top"
      style="width: 150px;"
      v-model="query.endTime"
      type="date"
      placeholder="结束日期">
    </el-date-picker>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      dealStatusList:[
        {
          label: '全部',
           value: '',
        },
        {
          label: '成功',
          value: 0,
        },
        {
          label: '待处理',
          value: 1,
        },
        {
          label: '处理中',
          value: 2,
        },
        {
          label: '处理失败',
          value: 3,
        }
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
<style scoped lang="less">
  .align-top {
    vertical-align: top;
  }
</style>
